
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index0PEMUtyvHKMeta } from "/home/runner/work/goscope.net/goscope.net/pages/demo/index.vue?macro=true";
import { default as demonwjKKQqq2zMeta } from "/home/runner/work/goscope.net/goscope.net/pages/goroutines/demo.vue?macro=true";
import { default as indexcRaFxp7TZyMeta } from "/home/runner/work/goscope.net/goscope.net/pages/goroutines/index.vue?macro=true";
import { default as indext9FHMOtTU5Meta } from "/home/runner/work/goscope.net/goscope.net/pages/index.vue?macro=true";
import { default as privacy_45policyNJpDt6MNqFMeta } from "/home/runner/work/goscope.net/goscope.net/pages/privacy-policy.vue?macro=true";
import { default as termsqzAk1doUZAMeta } from "/home/runner/work/goscope.net/goscope.net/pages/terms.vue?macro=true";
export default [
  {
    name: "demo",
    path: "/demo",
    component: () => import("/home/runner/work/goscope.net/goscope.net/pages/demo/index.vue")
  },
  {
    name: "goroutines-demo",
    path: "/goroutines/demo",
    component: () => import("/home/runner/work/goscope.net/goscope.net/pages/goroutines/demo.vue")
  },
  {
    name: "goroutines",
    path: "/goroutines",
    component: () => import("/home/runner/work/goscope.net/goscope.net/pages/goroutines/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/goscope.net/goscope.net/pages/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/runner/work/goscope.net/goscope.net/pages/privacy-policy.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/runner/work/goscope.net/goscope.net/pages/terms.vue")
  }
]